import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  where,
  doc,
  updateDoc,
} from "firebase/firestore/lite";
import { ALL_MAMBAS } from "./Profeten/constants";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export const getMambas = async () => {
  const col = collection(db, "mambas");
  const mambasSnapshot = await getDocs(col);
  const mambasList = mambasSnapshot.docs.map((doc) => doc.data());
  return mambasList;
};

export const getMambaLatestStatistics = async (mambaName) => {
  const q = query(
    collection(db, "golfbox_data", mambaName, "statistics"),
    orderBy("date", "asc"),
    limit(1)
  );

  const statisticsSnapshot = await getDocs(q);
  const statsList = statisticsSnapshot.docs.map((doc) => doc.data());
  return statsList;
};
export const getMambaStatistics = async (mambaName) => {
  const data = query(
    collection(db, "golfbox_data", mambaName, "statistics"),
    orderBy("date", "desc")
  );
  const statisticsSnapshot = await getDocs(data);
  const statsList = statisticsSnapshot.docs.map((doc) => doc.data());
  // combine name with stats
  return { name: mambaName, stats: statsList };
};

export const getMatchingUser = async (input) => {
  if (input.includes("@")) {
    return getMambas().then((users) => {
      return users.filter((user) => user.email === input).find((user) => user);
    });
  } else {
    return getMambas().then((users) => {
      return users
        .filter((user) => user.username === input)
        .find((user) => user);
    });
  }
};

export const createUser = async (username, name, email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      addDoc(collection(db, "mambas"), {
        username,
        email,
        name,
      });
      localStorage.removeItem("signedInUser");
      localStorage.setItem("signedInUser", username);

      return { signedIn: true, userCredential };
    })
    .catch((error) => {
      return error;
    });
};

export const signInUser = async (input, password) => {
  let email;
  let user;
  if (input.includes("@")) {
    console.log("EMAIL");
    user = await getMatchingUser(input);
    email = input;
    console.log(user, email);
  } else {
    console.log("USERNAME");
    user = await getMatchingUser(input);
    email = user.email;
  }
  if (user && email && password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.removeItem("signedInUser");
        localStorage.setItem("signedInUser", user.username);

        return { signedIn: true, userCredential };
      })
      .catch((error) => {
        return error;
      });
  }
};

export const makeGuess = async (username, guess) => {
  const col = collection(db, "mambas");
  const userQuery = query(col, where("username", "==", username));
  const userSnapshot = await getDocs(userQuery);
  let update;
  userSnapshot.forEach((user) => {
    const mambasRef = doc(db, "mambas", user.id);
    update = updateDoc(mambasRef, {
      guess24: guess,
    });
  });
  return update;
};

export const resetPassword = async (input) => {
  if (input.includes("@")) {
    return sendPasswordResetEmail(auth, input)
      .then(() => {
        return { success: true };
      })
      .catch((error) => {
        return error;
      });
  } else {
    const user = await getMatchingUser(input);
    if (user) {
      return sendPasswordResetEmail(auth, user.email)
        .then(() => {
          return { success: true };
        })
        .catch((error) => {
          return error;
        });
    }
  }
};

export const createSecretSantaList = (year) => {
  // const col = collection(db, "secretsanta");
  const document = doc(db, "secretsanta", year);

  const noshows = [
    "Peter William Skjensvold",
    "Lars Folkestad",
    "Mathias Dehli Klev",
    "Kristoffer Fagerdal Winge",
  ];
  const participants = ALL_MAMBAS.map((mamba) => mamba.name).filter(
    (mamba) => !noshows.includes(mamba)
  );
  /* create a function that takes in the participants array and returns a record of the 
  participants and their secret santa, you need to check that the secret santa is not the same as the 
  participant Everyone has to be a secret santa and everyone has to have a secret santa. the same person can not be assinged to multiple people */
  const createSecretSantaRecord = (participants) => {
    let assignments = {};
    let participantsCopy = [...participants];

    for (let participant of participants) {
      let potentialSantas = participantsCopy.filter((p) => p !== participant);
      if (potentialSantas.length === 0) {
        potentialSantas = participants.filter(
          (p) => p !== participant && !Object.values(assignments).includes(p)
        );
      }
      let secretSanta =
        potentialSantas[Math.floor(Math.random() * potentialSantas.length)];
      assignments[participant] = secretSanta;
      participantsCopy = participantsCopy.filter((p) => p !== secretSanta);
    }

    return assignments;
  };
  const secretSantaRecord = createSecretSantaRecord(participants);

  participants.forEach(() => {
    updateDoc(document, {
      ...secretSantaRecord,
    });
  });
};

export const getAssignedRecipient = async (year, username) => {
  const user = await getMatchingUser(username);
  const col = collection(db, "secretsanta");
  const dataSnapshot = await getDocs(col);
  return dataSnapshot.docs[0].data()[user.name];
};

export { app, db, auth };
