import { createContext, useContext, useEffect, useState } from "react";
import { ALL_MAMBAS } from "../Profeten/constants";
import { getMambaStatistics, getMambas } from "../firebase";
import { useQueries, useQuery } from "@tanstack/react-query";

const MambasContext = createContext();

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

const MambasContextProvider = (props) => {
  /* const cachedMambas = localStorage.getItem("mambas")
    ? Object.values(JSON.parse(localStorage.getItem("mambas")))
    : null; */

  const {
    data: mambasQueryData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["mambas"],
    queryFn: getMambas,
    staleTime: Infinity,
  });
  console.log("🚀 ~ MambasContextProvider ~ mambasQueryData:", mambasQueryData);

  const results = useQueries({
    queries: ALL_MAMBAS.map((m) => ({
      queryKey: ["mambasStatistics", m.name],
      queryFn: () => getMambaStatistics(m.name),
      staleTime: Infinity,
      combine: (results) => {
        return {
          data: results.map((result) => result.data),
          pending: results.some((result) => result.isPending),
        };
      },
    })),
  });

  const [mambasStatistics, setMambasStatistics] = useState(null);
  console.log(
    "🚀 ~ MambasContextProvider ~ mambasStatistics:",
    mambasStatistics
  );

  useEffect(() => {
    if (mambasStatistics !== null) return;
    if (results.every((r) => !r.isPending)) {
      setMambasStatistics(
        results
          .map((r) => r.data)
          .toSorted((a, b) => {
            if (a.stats?.[0]?.hcp === undefined) {
              return 1;
            }
            return a.stats?.[0]?.hcp > b.stats[0]?.hcp ? 1 : -1;
          })
      );
    }
  }, [mambasStatistics, results]);

  /* useEffect(() => {
    populatedMambas.forEach(async (mamba) => {
      getMambaStatistics(mamba.name).then((stats) => {
        mamba.statistics = formatStats(stats);
        localStorage.setItem(
          "mambas",
          JSON.stringify({ last_update: new Date(), ...populatedMambas })
        );
        setPopulatedMambas(populatedMambas);
      });
    });
  }, [populatedMambas]); */

  const mambasData = { mambasStatistics, mambasQueryData };

  return <MambasContext.Provider value={mambasData} {...props} />;
};

const useMambas = () => useContext(MambasContext);

export { MambasContextProvider, useMambas };
