import { useState } from "react";
import ge from "./ge.gif";
import geTrym from "./ge-trym.gif";
// import { calculateScores } from "../Profeten/powerranking";
// import { calculateScore } from "../Profeten/calculation";
// import { result2023 } from "../Profeten/constants";
// import { getMambas } from "../firebase";

const CountDown = () => {
  const countDownDate = new Date("Aug 3, 2024 09:00:00").getTime();
  const [days, setDays] = useState(undefined);
  const [hours, setHours] = useState(undefined);
  const [minutes, setMinutes] = useState(undefined);
  const [seconds, setSeconds] = useState(undefined);

  setInterval(() => {
    const now = new Date().getTime();
    const timeleft = countDownDate - now;

    setDays(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((timeleft % (1000 * 60)) / 1000));
  }, 1000);
  return (
    <>
      {days ? (
        <h1>
          {days} : {hours} : {minutes} : {seconds}
        </h1>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
};

/* getMambas().then((mambas) => {
  const scores = calculateScores(mambas);
  console.log(scores);
}); */
/* getMambas().then((mambas) => {
  mambas.forEach((mamba) => {
    console.log(mamba.name);
    calculateScore(mamba.guess23, result2023);
  });
}); */

export const MainPage = () => {
  const [showTheGif, setShowTheGif] = useState(false);
  return (
    <div style={{ maxWidth: "100%" }}>
      <h1>
        <b>VELKOMMEN TIL MAMBAS INVITATIONAL</b>
      </h1>
      <p>KANSKJE DEN MEST EKSKLUSIVE GOLF TURNERINGEN I VERDEN</p>
      <p>NEDTELLING TIL MAMBAS 2024 3. August </p>
      <CountDown />

      {!showTheGif ? (
        <img
          alt="ge"
          src={ge}
          fluid
          style={{ padding: "2rem", width: "100%" }}
          onClick={() => setShowTheGif((prev) => !prev)}
        />
      ) : (
        <img
          alt="geTtrym"
          src={geTrym}
          fluid
          style={{ padding: "2rem" }}
          onClick={() => setShowTheGif((prev) => !prev)}
        />
      )}
    </div>
  );
};
