export const ALL_MAMBAS = [
  {
    id: "id-1",
    name: "Fredrik Lugg",
  },
  {
    id: "id-2",
    name: "Peter William Skjensvold",
  },
  {
    id: "id-3",
    name: "Mathias Dehli Klev",
  },
  {
    id: "id-4",
    name: "Trym Frafjord-Moe",
  },
  {
    id: "id-5",
    name: "Andreas Horstad",
  },
  {
    id: "id-6",
    name: "Carl Oscar Northug",
  },
  {
    id: "id-7",
    name: "Gustav Emil Dietrichson",
  },
  {
    id: "id-8",
    name: "Kristoffer Fagerdal Winge",
  },
  {
    id: "id-9",
    name: "Lasse Killingmo",
  },
  {
    id: "id-10",
    name: "Eirik Bergsjø",
  },
  {
    id: "id-11",
    name: "Eirik Skaflem Lunde",
  },
  {
    id: "id-12",
    name: "Herman Rosell-Helland",
  },
  {
    id: "id-13",
    name: "Olav Meberg",
  },
  {
    id: "id-14",
    name: "Thomas Malm",
  },
  {
    id: "id-15",
    name: "Fredrik Mellbye",
  },
  {
    id: "id-16",
    name: "Erik Theodor Torgersen",
  },
  {
    id: "id-17",
    name: "Carsten Ditlev-Simonsen",
  },
  {
    id: "id-18",
    name: "Lars Folkestad",
  },
];

export const result2023 = {
  1: "Gustav Emil Dietrichson",
  2: "Andreas Horstad",
  3: "Peter William Skjensvold",
  4: "Fredrik Lugg",
  5: "Eirik Skaflem Lunde",
  6: "Eirik Bergsjø",
  7: "Lasse Killingmo",
  8: "Trym Frafjord-Moe",
  9: "Carl Oscar Northug",
  10: "Erik Theodor Torgersen",
  11: "Carsten Ditlev-Simonsen",
  12: "Mathias Dehli Klev",
  13: "Fredrik Mellbye",
  14: "Lars Folkestad",
  15: "Olav Meberg",
  16: "Thomas Sand Malm",
  17: "Herman Rosell-Helland",
};
