import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInUser, resetPassword } from "../firebase";
import React from "react";
import { TextField, Button, Stack } from "@mui/material";

export const SignInPage = () => {
  const navigate = useNavigate();
  const cachedUser = localStorage.getItem("signedInUser");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [checkYourEmail, setCheckYourEmail] = useState(false);

  useEffect(() => {
    if (cachedUser) {
      navigate("/profeten/" + cachedUser);
    }
  }, [cachedUser, navigate]);

  const handleSubmit = () => {
    if (username && password) {
      // If password is filled authenticate and redirect.
      signInUser(username, password)
        .then((result) => {
          setError("");
          if (result.signedIn) {
            navigate("/profeten/" + username);
            return;
          }
        })
        .catch((error) => {
          setError("Noe gikk galt ved innlogging");
        });
    }
    /* if (
      showRegistration &&
      e.target.registrationEmail &&
      e.target.registrationPassword
    ) {
      createUser(
        e.target.username.value,
        e.target.registrationName.value,
        e.target.registrationEmail.value,
        e.target.registrationPassword.value
      ).then((res) => {
        if (res.signedIn) {
          navigate("/profeten/" + e.target.username.value);
        }
      });
    } */

    /* getMatchingUser(username)
      .then((result) => {
        if (result) {
          setUser(result);
          setShowPassword(true);
        }
      })
      .catch((error) => {
        setError(error.message);
      }); */
    setError("Kunne ikke logge inn eller finner ikke bruker");
  };

  const handleResetPassword = () =>
    resetPassword(username)
      .then(() => {
        setCheckYourEmail(true);
        setShowResetPassword(false);
        setError("");
      })
      .catch((error) => {
        setError(error.message);
      });

  return !cachedUser ? (
    <>
      <Stack spacing={2} alignItems="center">
        <div>Logg inn for å levere profeten</div>
        {showResetPassword && (
          <div>
            Skriv inn epost eller brukernavn så får du mail for å tilbakestille
            passord
          </div>
        )}
        {checkYourEmail && <div>Sjekk eposten din for å tilbakestille</div>}
        <TextField
          label="Brukernavn eller epost"
          variant="outlined"
          sx={{
            width: "300px",
            backgroundColor: "white",
            borderRadius: "5px",
            color: "black",
          }}
          onChange={(e) => setUsername(e.target.value)}
        />
        {!showResetPassword && (
          <TextField
            label="Passord"
            variant="outlined"
            sx={{
              width: "300px",
              backgroundColor: "white",
              borderRadius: "5px",
              color: "black",
            }}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        )}
        {!showResetPassword ? (
          <Button
            color="inherit"
            variant="text"
            size="small"
            onClick={() => setShowResetPassword(true)}
          >
            Glemt passord?
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleResetPassword()}
            >
              Send meg mail for å tilbakestille passord
            </Button>
            <Button
              color="inherit"
              variant="text"
              size="small"
              onClick={() => {
                setShowResetPassword(false);
                setCheckYourEmail(false);
              }}
            >
              Tilbake til innlogging
            </Button>
          </>
        )}
        {!showResetPassword && (
          <Button variant="contained" onClick={() => handleSubmit()}>
            Logg inn
          </Button>
        )}
        {error && <div>{error}</div>}
      </Stack>
    </>
  ) : null;
};
