import { TextField, Stack, Button } from "@mui/material";
import React, { useState } from "react";
import { signInUser, resetPassword, getAssignedRecipient } from "../firebase";

function SecretSanta() {
  const cachedUser = localStorage.getItem("signedInUser");
  const [user, setUser] = useState(cachedUser);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [recipient, setRecipient] = useState("");

  if (user) {
    getAssignedRecipient("2023", user).then((result) => {
      setRecipient(result);
    });
  }

  const handleSubmit = () => {
    if (password) {
      // If password is filled authenticate and redirect.
      signInUser(username, password)
        .then((result) => {
          setUser(localStorage.getItem("signedInUser"));
        })
        .catch((error) => {
          setError("Feil brukernavn eller passord");
        });
    }
  };

  return (
    <div>
      {!!user ? (
        <>
          <h1>🎁🎁 Hello, {user}! 🎁🎁 </h1>
          <h1>Du er hemmelig nisse for {recipient}!</h1>
        </>
      ) : (
        <>
          <Stack spacing={2} alignItems="center">
            <div>Logg inn for å se hvem du er hemmelig nisse for</div>
            <TextField
              label="Brukernavn eller epost"
              variant="outlined"
              sx={{
                width: "300px",
                backgroundColor: "white",
                borderRadius: "5px",
                color: "black",
              }}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Passord"
              variant="outlined"
              sx={{
                width: "300px",
                backgroundColor: "white",
                borderRadius: "5px",
                color: "black",
              }}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <Button
              color="inherit"
              variant="text"
              size="small"
              onClick={() => resetPassword(username)}
            >
              glemt passord?
            </Button>
            <Button variant="contained" onClick={() => handleSubmit()}>
              Logg inn
            </Button>
            {error && <div>{error}</div>}
          </Stack>
        </>
      )}
      {/* <Button variant="contained" onClick={() => createSecretSantaList("2023")}>
        Make SS
      </Button> */}
    </div>
  );
}

export default SecretSanta;
